import styles from './CompanySection.module.css';
import classNames from 'classnames/bind';
import { motion, Variants } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';

const cx = classNames.bind(styles);

const TextVariants: Variants = {
  offscreen: {
    y: 100,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0.1,
      duration: 1.0,
    },
  },
};

function CompanySection() {
  const { t } = useTranslation('home');

  return (
    <motion.section
      className={cx('company', 'common-center-style')}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.5 }}
    >
      <motion.div variants={TextVariants} className={cx('motion-container')}>
        <strong className={cx('title')}>
          <Trans
            i18nKey={t('company-header')}
            components={{
              br: <br className="disable-mobile-break disable-tablet-break" />,
            }}
          />
        </strong>
        <span className={cx('description')}>
          <Trans
            i18nKey={t('company-description')}
            components={{
              br: <br className="disable-mobile-break disable-tablet-break" />,
              div: <div className={cx('description-item')}></div>,
            }}
          />
        </span>
      </motion.div>
    </motion.section>
  );
}

export default CompanySection;
