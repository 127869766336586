import { INewsList } from 'types/news';

import styles from './NewsList.module.css';
import classNames from 'classnames/bind';

import dayjs from 'dayjs';
import Link from 'next/link';
import { RightOutlined } from '@ant-design/icons';

const cx = classNames.bind(styles);

interface INewsListProps {
  data: INewsList['data'];
}

function NewsList({ data }: INewsListProps) {
  return (
    <ul className={cx('wrapper')}>
      {data.map(({ id, attributes }) => (
        <Link key={id} passHref href={`news/pr/${id}?page=1`}>
          <li className={cx('list-item')}>
            <span className={cx('title')}>{attributes.title}</span>
            <span className={cx('expand-content')}>
              <span className={cx('date')}>
                {dayjs(attributes.displayDate).format('YYYY.MM.DD')}
              </span>
              <RightOutlined className={cx('more-icon')} />
            </span>
          </li>
        </Link>
      ))}
    </ul>
  );
}

export default NewsList;
