import { RightOutlined } from '@ant-design/icons';
import styles from './BusinessSection.module.css';
import classNames from 'classnames/bind';

import { motion, Variants } from 'framer-motion';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';

const cx = classNames.bind(styles);

const TextVariants: Variants = {
  offscreen: {
    opacity: 0,
  },
  onscreen: {
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0.1,
      duration: 1,
    },
  },
};

function BusinessSection() {
  const { t } = useTranslation('home');
  const { t: ct } = useTranslation('common');
  return (
    <motion.section
      className={cx('business')}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.5 }}
    >
      <div className={cx('common-center-style')}>
        <Link href="/business">
          <strong className={cx('title')}>{t('business')}</strong>
        </Link>
        <motion.div className={cx('card-wrapper')} variants={TextVariants}>
          <div className={cx('card')}>
            <strong className={cx('title')}>{t('content')}</strong>
            <span className={cx('description')}>
              <Trans
                i18nKey={t('content-description')}
                components={{
                  br: <br className="disable-pc-break disable-mobile-break" />,
                }}
              />
            </span>
            <Link href="/business/#content">
              <a className={cx('more')}>
                <span>{ct('find-more')}</span> <RightOutlined />
              </a>
            </Link>
          </div>
          <div className={cx('card')}>
            <strong className={cx('title')}>{t('partnership')}</strong>
            <span className={cx('description')}>
              <Trans
                i18nKey={t('partnership-description')}
                components={{
                  br: <br className="disable-pc-break disable-mobile-break" />,
                }}
              />
            </span>
            <Link href="/business/#partnership" className={cx('more')}>
              <a className={cx('more')}>
                <span>{ct('find-more')}</span> <RightOutlined />
              </a>
            </Link>
          </div>
          <div className={cx('card')}>
            <strong className={cx('title')}>{t('merchandise')}</strong>
            <span className={cx('description')}>
              <Trans
                i18nKey={t('merchandise-description')}
                components={{
                  br: <br />,
                }}
              />
            </span>
            <Link href="/business/#product" className={cx('more')}>
              <a className={cx('more')}>
                <span>{ct('find-more')}</span> <RightOutlined />
              </a>
            </Link>
          </div>
        </motion.div>
      </div>
    </motion.section>
  );
}

export default BusinessSection;
