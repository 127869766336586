import qs from 'qs';
import useAPI from 'hooks/useAPI';

import styles from './NewsSection.module.css';
import classNames from 'classnames/bind';
import { IQuery } from 'types/common';
import { useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { INewsItem, INewsList } from 'types/news';
import { useRouter } from 'next/router';
import { Empty } from 'antd';
import NewsList from './NewsList';
import AnnouncementListSkeleton from 'components/common/skeleton/news/AnnouncementListSkeleton';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';

const cx = classNames.bind(styles);

interface IGetNewsList {
  locale?: 'ko' | 'en';
}

function NewsSection() {
  const { t } = useTranslation('home');

  const router = useRouter();
  const { locale } = router;

  const stringifyQuery = (query: IQuery) => qs.stringify(query);

  const getNewsList = useCallback(async ({ locale = 'ko' }: IGetNewsList) => {
    const route = locale === 'ko' ? 'news-kos' : 'news-ens';
    const query: IQuery = {
      pagination: {
        page: 1,
        pageSize: 4,
      },
      populate: '*',
      sort: 'displayDate:desc',
    };
    const newsData: AxiosResponse<INewsList> = await axios.get(
      `/api/${route}?${stringifyQuery(query)}`
    );
    return newsData;
  }, []);

  const { data, isLoading, isError } = useAPI<INewsItem[]>((args) =>
    getNewsList({ locale, ...args })
  );

  const renderer = () => {
    if (isLoading) {
      return <AnnouncementListSkeleton dataLength={4} />;
    }
    if (isError) {
      /* TODO: 에러 화면 정리 */
      return <>에러가 발생했습니다.</>;
    }
    if (!!data?.data.length) {
      return <NewsList data={data.data} />;
    }
    return (
      /* TODO: 빈 화면 정리 */
      <Empty description="등록된 보도자료가 없습니다." />
    );
  };

  return (
    <section className={cx('news', 'common-center-style')}>
      <Link href="/news/pr?page=1">
        <strong className={cx('title')}>{t('news-room')}</strong>
      </Link>
      {renderer()}
    </section>
  );
}

export default NewsSection;
