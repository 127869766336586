
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import type { NextPage } from 'next';
import styles from 'styles/home.module.css';
import classNames from 'classnames/bind';
import BusinessSection from 'components/home/BusinessSection';
import CompanySection from 'components/home/CompanySection';
import NewsSection from 'components/home/NewsSection';
import CareerSection from 'components/home/CareerSection';
import { useRouter } from 'next/router';
const cx = classNames.bind(styles);
const Home: NextPage = () => {
    const router = useRouter();
    const { locale } = router;
    return (<div className={cx('container')}>
      <section className={cx('banner-wrapper')}></section>
      <CompanySection />
      <BusinessSection />
      <NewsSection />
      {locale === 'ko' && <CareerSection />}
    </div>);
};
export default Home;

    async function __Next_Translate__getStaticProps__190ed1d4059__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190ed1d4059__ as getStaticProps }
  